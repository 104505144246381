.modal-dialog-meetingpopup{
    max-width: 100% !important;
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
}
.modal-content-meetingpopup{
    height: 100%;
    min-height: 100%;
    height: auto;
    border-radius: 0;
}

.holds-the-iframe {
    /* background-color: black; */
    background:url('/loading.gif') center center no-repeat;
  }