.shadow1 {
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}
.card1 {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e3e6f0;
    border-radius: .35rem;
}
.border-left-success {
    border-left: .25rem solid #1cc88a!important;
}
.border-left-info {
    border-left: .25rem solid #36b9cc!important;
}
.border-left-warning{
    border-left: .25rem solid #e74a3b!important;
}
.border-left-warning{
    border-left: .25rem solid #e74a3b!important;
}
.border-left-primary {
    border-left: .25rem solid #007bff !important;
}
.text-gray-300 {
    color: #dddfeb!important;
}
.text-primary1{
    color: #007bff !important;
}

.card .card-header[data-toggle=collapse]::after {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 1.725rem;
    line-height: 51px;
    font-weight: 900;
    content: '\f107';
    font-family: 'Font Awesome 5 Free';
    color: #d1d3e2;
}

.card .card-header[data-toggle=collapse].collapsed::after {
    content: '\f105';
}