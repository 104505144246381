thead {
    border-top: 1px solid #dee2e6;
}

tbody + thead{
    display: none;
  }

.text-gray-300 {
    color: #5a5c69!important;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before {
bottom: .5em;
}

tbody>tr>:nth-child(5){
  text-align:center;
 }

/* 
.dataTables_wrapper .table thead tr th:nth-child(1),
.dataTables_wrapper .table tbody tr td:nth-child(1)
{
  display: none;
 }  */